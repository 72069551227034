import React, { useEffect, useState } from "react";
import { FaEye, FaChevronDown } from "react-icons/fa";
import { Child, useApi } from "../../../../context/ApiContext";

interface ChildPreviewModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  children: Child[];
}

interface SubscriptionModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  subscriptions: any[];
}

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({
  isOpen,
  onRequestClose,
  subscriptions,
}) => {
  return (
    <div
      className={`fixed flex items-center justify-center z-50 transition-opacity duration-300 ${
        !isOpen && "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed bg-black bg-opacity-50 transition-opacity duration-300"
        onClick={onRequestClose}
      ></div>
      <div className="bg-white rounded-lg shadow-2xl p-8 z-10 w-full max-w-6xl mx-6 flex flex-col gap-8 max-h-[80vh] overflow-auto transition-all transform duration-500">
        <h2 className="text-4xl font-semibold text-center w-full text-gray-800">
          Subscriptions Details
        </h2>

        <div className="space-y-6 w-full">
          {subscriptions.map((subscription) => (
            <div
              key={subscription.id}
              className="p-6 bg-gray-50 rounded-lg shadow-lg transition-transform duration-300 transform hover:scale-105"
            >
              <p className="text-xl text-gray-800">
                <strong>Type:</strong> {subscription.subscription_type}
              </p>
              <p className="text-xl text-gray-600">
                <strong>Start Date:</strong> {subscription.start_date}
              </p>
              <p className="text-xl text-gray-600">
                <strong>End Date:</strong> {subscription.end_date}
              </p>
              <p className="text-xl text-gray-600">
                <strong>Status:</strong> {subscription.is_active ? "Active" : "Inactive"}
              </p>
            </div>
          ))}
        </div>

        <div className="flex justify-end mt-6 w-full">
          <button
            className="bg-red-600 text-white px-8 py-4 rounded-lg hover:bg-red-700 transition duration-200"
            onClick={onRequestClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const ChildPreviewModal: React.FC<ChildPreviewModalProps> = ({
  isOpen,
  onRequestClose,
  children,
}) => {
  const [visibleChildren, setVisibleChildren] = useState<Child[]>([]);
  const { subscriptions } = useApi();  // Fetching subscriptions from the API context
  const [selectedChildSubscriptions, setSelectedChildSubscriptions] = useState<any[]>([]);  // To store selected child's subscriptions
  const [expandedChild, setExpandedChild] = useState<number | null>(null); // Track which child's details are expanded
  const [isSubscriptionModalOpen, setSubscriptionModalOpen] = useState<boolean>(false); // Modal for subscriptions

  useEffect(() => {
    if (isOpen) {
      setVisibleChildren(children);
    }
  }, [isOpen, children]);

  const handleViewSubscriptions = (childId: number) => {
    const childSubscriptions = subscriptions.filter(sub => sub.child === childId);
    setSelectedChildSubscriptions(childSubscriptions);
    setSubscriptionModalOpen(true);  // Open the subscription modal
  };

  const toggleChildDetails = (childId: number) => {
    setExpandedChild(expandedChild === childId ? null : childId); // Toggle the expanded state of the child
  };
  const isSubscriptionActive = (childId: number): boolean => {
    // Filter subscriptions for the specific child
    const childSubscriptions = subscriptions.filter(sub => sub.child === childId);

  
    if (!childSubscriptions.length) return false; // No subscriptions found
  
    // Sort subscriptions by end date descending
    const sortedSubscriptions = childSubscriptions.sort(
      (a, b) => new Date(b.end_date).getTime() - new Date(a.end_date).getTime()
    );
  
    // Get the latest subscription
    const latestSubscription = sortedSubscriptions[0];
  
    // Check if the current date is within the start and end date of the latest subscription
    const currentDate = new Date();
    const startDate = new Date(latestSubscription.start_date);
    const endDate = new Date(latestSubscription.end_date);
  
    return currentDate >= startDate && currentDate <= endDate;
  };
  
  if (!isOpen || !visibleChildren.length) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300"
        onClick={onRequestClose}
      ></div>
      <div className="bg-white rounded-lg shadow-lg p-6 z-10 w-fit max-w-4xl mx-4 flex flex-col gap-6 max-h-[90vh] ">
        <h2 className="text-3xl font-semibold text-center w-full text-gray-800">
          Children Preview ({children.length})
        </h2>

        <div className="bg-white  rounded-lg border  duration-200 min-w-[400px] h-[500px]  flex flex-row overflow-x-auto align-middle   gap-5 px-4 ">
        {visibleChildren.map((child) => (
            <div
              key={child.id}
              className="bg-white  shadow rounded-lg border hover:scale-95 duration-200  min-w-[400px] my-auto h-[310px]  flex flex-col justify-between"  // Ensuring same width and height
            >
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {child.name}
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    <span className={` mr-2 ${isSubscriptionActive(child.id) ?'bg-green-700 w-8 h-8' : 'bg-red-700 w-8 h-8 ' }`}>{' '}. {' '}</span>
                     {' '}Age: {child.age}
                    <span className={` mr-2 px-2 rounded ml-2  ${isSubscriptionActive(child.id) ?'bg-green-700 w-8 h-8 text-white' : 'bg-red-700 w-8 h-8 text-white ' }`}> {isSubscriptionActive(child.id) ?'active' : 'subscription ended' }</span>
                </p>
              </div>

              <div className="border-t border-gray-200 px-4 py-5 sm:p-0 h-[320px] bg-slate-100">
                <dl className="sm:divide-y sm:divide-gray-200 flex flex-col">
                  <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Experience</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {child.experience}
                    </dd>
                  </div>
                  <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Username</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {child.clear_username}
                    </dd>
                  </div>
                  <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Password</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {child.clear_password}
                    </dd>
                  </div>
                  <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Parent ID</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {child.parent}
                    </dd>
                  </div>
                </dl>
              </div>

              <div className="flex justify-between px-2 items-center mt-4 align-bottom  bg-gray-100">
                <button
                  onClick={(e) => {
                    e.stopPropagation();  // Prevent toggling child details when clicking this button
                    handleViewSubscriptions(child.id);
                  }}
                  className="text-blue-600 flex items-center hover:underline"
                >
                  <FaEye className="mr-2" /> View Subscriptions
                </button>

                {/* <button
                  onClick={() => toggleChildDetails(child.id)}
                  className="text-gray-600 flex items-center hover:underline"
                >
                  <FaChevronDown className="mr-2" /> More Details
                </button> */}
              </div>

              {/* Show details if expanded */}
              {expandedChild === child.id && (
                <div className="mt-4 text-gray-800">
                  <p className="text-sm text-gray-600">Additional Information...</p>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="flex justify-end mt-4 w-full">
          <button
            className="bg-red-600 text-white px-6 py-3 rounded-lg hover:bg-red-700 transition duration-200"
            onClick={onRequestClose}
          >
            Close
          </button>
        </div>
      </div>

      {/* Subscription Modal */}
      <SubscriptionModal
        isOpen={isSubscriptionModalOpen}
        onRequestClose={() => setSubscriptionModalOpen(false)}
        subscriptions={selectedChildSubscriptions}
      />
    </div>
  );
};


export default ChildPreviewModal;
