// components/UserProfile.tsx

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import { AuthContext } from '../../context/AuthContext'; // Assuming you have an Auth context
import UserTable from '../../components/UserTable';
import EditUserModal from '../../components/EditUserModal';
import ResetPasswordModal from '../../components/ResetPasswordModal';

export interface User {
  id: number;
  username: string;
  phone_number: string;
  email?: string;
  user_type: string;
}

const UserProfile: React.FC = () => {
  const authContext = useContext(AuthContext);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [formData, setFormData] = useState({
    username: '',
    phone_number: '',
    email: '',
    user_type: 'student',
  });
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isResetModalOpen, setResetModalOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isProcessing, setProcessing] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  // Backend base URL
  const BASE_URL = 'https://codeoceantech.pythonanywhere.com///api/accounts';

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/users/`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users', error);
    }
  };

  const handleEditClick = (user: User) => {
    setSelectedUser(user);
    setFormData({
      username: user.username,
      phone_number: user.phone_number,
      email: user.email || '',
      user_type: user.user_type,
    });
    setEditModalOpen(true);
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (selectedUser) {
        await axios.put(`${BASE_URL}/users/${selectedUser.id}/`, formData);
        fetchUsers(); // Refresh the users list after updating
        setSelectedUser(null);
        setEditModalOpen(false);
      }
    } catch (error) {
      console.error('Error updating user', error);
    }
  };

  const handleResetPassword = (user: User) => {
    setSelectedUser(user);
    setResetModalOpen(true);
  };

  const submitResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }

    setProcessing(true);
    try {
      if (selectedUser) {
        await axios.put(`${BASE_URL}/users/${selectedUser.id}/reset-password/`, {
          phone_number: selectedUser.phone_number,
          new_password: newPassword,
          confirm_password: confirmPassword,
        });
        setSuccessMessage('Password reset successful!');
      }
      setProcessing(false);
    } catch (error) {
      console.error('Error resetting password', error);
      setProcessing(false);
    }
  };

  const closeResetModal = () => {
    setResetModalOpen(false);
    setNewPassword('');
    setConfirmPassword('');
    setSuccessMessage('');
    setProcessing(false);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    setSelectedUser(null);
  };

  return (
    <div className="p-6 bg-gray-100">
      <UserTable
        users={users}
        onEdit={handleEditClick}
        onResetPassword={handleResetPassword}
      />

      {selectedUser && (
        <EditUserModal
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          formData={formData}
          onChange={handleFormChange}
          onSubmit={handleUpdateSubmit}
          userTypes={['student', 'manager', 'parent', 'instructor']}
        />
      )}

      <ResetPasswordModal
        isOpen={isResetModalOpen}
        onClose={closeResetModal}
        onSubmit={submitResetPassword}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        isProcessing={isProcessing}
        successMessage={successMessage}
      />
    </div>
  );
};

export default UserProfile;
