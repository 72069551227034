import React, { useState } from "react";
import Card from "../../cards/Cards";
import CardHeader from "../../cards/CardHeader";
import CardBody from "../../cards/CardBody";
import CardFooter from "../../cards/CardFooter";
import { Course, useApi } from "../../../../context/ApiContext";
import { AiFillEye } from "react-icons/ai";
import { IoClose } from "react-icons/io5";

interface CourseProps {
  course: Course;
}

const CourseContainer: React.FC<CourseProps> = ({ course }) => {
  const { child, parents } = useApi(); // Access child data from the API context
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString();
  };

  // Filter children subscribed to the current course
  const enrolledChildren = child.filter((c) => c.courses.includes(course.id));

  return (
    <>
      <Card style={{ width: "fit-content" }}>
        <CardHeader color="bg-green-500">
          <div color="primary" className="text-xl font-semibold text-white">
            {course.title}
          </div>
        </CardHeader>
        <CardBody>
          <p>
            <div className="bg-blue-400 px-4 rounded-2xl py-1 text-white my-1">
              Start Date: {formatDate(course.start_time)}
            </div>
          </p>
          <p>
            {course.end_time ? (
              <div className="bg-yellow-100 px-4 rounded-2xl py-1 text-gray-700 my-1">
                End Date: {formatDate(course.end_time)}
              </div>
            ) : (
              <div className="bg-green-300 px-4 rounded-2xl py-1 text-white">
                Ongoing
              </div>
            )}
          </p>
        </CardBody>
        <CardFooter>
          <p className="text-gray-700">Course Sessions: {course.sessions.length}</p>
          <p className="text-gray-700">Course Subscription: {enrolledChildren.length}</p>
          <div className="flex items-center gap-2 mt-2">
            <p
              className="cursor-pointer text-blue-500 flex items-center hover:text-blue-700"
              onClick={() => setIsModalOpen(true)}
            >
              <AiFillEye size={20} className="mr-1" /> View Enrolled Children
            </p>
          </div>
        </CardFooter>
      </Card>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-bold text-gray-700">
                Enrolled Children
              </h2>
              <button
                className="text-gray-500 hover:text-gray-800"
                onClick={() => setIsModalOpen(false)}
              >
                <IoClose size={24} />
              </button>
            </div>
            <ul className="mt-4 max-h-60 overflow-y-auto divide-y divide-gray-300">
              {enrolledChildren.length > 0 ? (
                enrolledChildren.map((child) => {
                  const parent = parents.find((p) => p.id === child.parent); // Find the parent for the child
                  return (
                    <li key={child.id} className="py-3 flex justify-between items-center">
                      <div>
                        <p className="font-semibold">{child.name}</p>
                        <p className="text-sm text-gray-600">
                          Age: {child.age}
                          {parent && (
                            <>
                              {" "}
                              - Parent: {parent.user.username}
                            </>
                          )}
                        </p>
                      </div>
                      {parent && (
                        <a
                          href={`tel:${parent.user.phone_number}`}
                          className="text-blue-500 underline hover:text-blue-700 text-sm"
                        >
                          {parent.user.phone_number}
                        </a>
                      )}
                    </li>
                  );
                })
              ) : (
                <li className="text-gray-500 text-center py-3">
                  No children enrolled in this course
                </li>
              )}
            </ul>
            <div className="mt-4 flex justify-end">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
                onClick={() => setIsModalOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CourseContainer;
