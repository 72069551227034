import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Session, Course, useApi } from '../../context/ApiContext';

interface InstructorSessionsModalProps {
  instructorId?: number;
  onClose: () => void;
}

const ProgressBar: React.FC<{ progress: number }> = ({ progress }) => (
  <div className="relative w-full h-4 bg-gray-300 rounded-full overflow-hidden">
    <div
      className="absolute top-0 left-0 h-full bg-blue-600 rounded-full transition-all duration-300"
      style={{ width: `${progress}%` }}
    ></div>
  </div>
);

const SelectInput: React.FC<{
  label: string;
  value: number | null;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Array<{ value: number; label: string }>;
  required?: boolean;
}> = ({ label, value, onChange, options, required = false }) => (
  <div className="mb-4">
    <label className="block text-lg font-semibold mb-2">{label}</label>
    <select
      value={value || ''}
      onChange={onChange}
      className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-150"
      required={required}
    >
      <option value="" disabled>
        Select an option
      </option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

const InstructorSessionsModal: React.FC<InstructorSessionsModalProps> = ({ instructorId, onClose }) => {
  const { sessions, courses, fetchSessions } = useApi();
  const [sessionsForInstructor, setSessionsForInstructor] = useState<Session[]>([]);
  const [selectedSessionId, setSelectedSessionId] = useState<number | null>(null);
  const [selectedCourseId, setSelectedCourseId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [sessionsNotAssigned, setSessionsNotAssigned] = useState<Session[]>([]);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const instructorSessions = sessions.filter(session => session.instructor_attended === instructorId);
    const unassignedSessions = sessions.filter(session => !session.instructor_attended);
    setSessionsForInstructor(instructorSessions);
    setSessionsNotAssigned(unassignedSessions);
  }, [sessions, instructorId]);

  const handleCourseChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const courseId = Number(e.target.value);
    setSelectedCourseId(courseId);

    if (courseId) {
      setLoading(true);
      setProgress(0);

      try {
        const sessionsToUpdate = sessions.filter(session => session.course === courseId && !session.instructor_attended);

        for (let i = 0; i < sessionsToUpdate.length; i++) {
          const session = sessionsToUpdate[i];
          await axios.patch(`https://codeoceantech.pythonanywhere.com//api/sessions/${session.id}/`, {
            instructor_attended: instructorId,
          });
          setProgress(((i + 1) / sessionsToUpdate.length) * 100);
        }
        fetchSessions();
      } catch (error) {
        console.error('Error updating sessions:', error);
        alert('An error occurred while updating sessions. Please try again.');
      } finally {
        setLoading(false);
        setSelectedCourseId(null);
      }
    } else {
      setSessionsForInstructor([]);
    }
  };

  const handleAssignSession = async () => {
    if (instructorId && selectedSessionId) {
      setLoading(true);
      try {
        await axios.patch(`https://codeoceantech.pythonanywhere.com//api/sessions/${selectedSessionId}/`, {
          instructor_attended: instructorId,
        });
        fetchSessions();
      } catch (error) {
        console.error('Error assigning session:', error);
        alert('An error occurred while assigning the session. Please try again.');
      } finally {
        setLoading(false);
        setSelectedSessionId(null);
      }
    }
  };

  const handleUnassignSession = async (sessionId: number) => {
    setLoading(true);
    try {
      await axios.patch(`https://codeoceantech.pythonanywhere.com//api/sessions/${sessionId}/`, {
        instructor_attended: null,
      });
      setSessionsForInstructor(prevSessions => prevSessions.filter(session => session.id !== sessionId));
      fetchSessions();
    } catch (error) {
      console.error('Error unassigning session:', error);
      alert('An error occurred while unassigning the session. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const availableCourses = courses.filter(course =>
    sessions.some(session => session.course === course.id && !session.instructor_attended)
  );

  useEffect(() => {
    closeButtonRef.current?.focus();
  }, []);

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 "
      role="dialog"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      tabIndex={-1}
      onKeyDown={(e) => e.key === 'Escape' && onClose()}
    >
      <div className="bg-white p-8 rounded-lg shadow-lg w-2/4  overflow-y-auto outline-none transition-transform duration-200 max-h-[80vh] ">
        <h2 id="modal-title" className="text-2xl font-semibold mb-6 text-gray-800">
          Sessions for Instructor ID: {instructorId}
        </h2>

        <SelectInput
          label="Select Course:"
          value={selectedCourseId}
          onChange={handleCourseChange}
          options={availableCourses.map(course => ({ value: course.id, label: course.title }))}
          required
        />

        {loading && <ProgressBar progress={progress} />}

        <SelectInput
          label="Assign New Session:"
          value={selectedSessionId}
          onChange={(e) => setSelectedSessionId(Number(e.target.value))}
          options={sessionsNotAssigned.map(session => ({ value: session.id, label: session.title }))}
        />
        <button
          onClick={handleAssignSession}
          className={`bg-blue-600 text-white px-4 py-2 rounded-lg mt-4 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-opacity ${
            loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'
          }`}
          disabled={!selectedSessionId || loading}
        >
          {loading ? 'Assigning...' : 'Assign'}
        </button>

        <table className="table-auto w-full text-left mb-6 mt-6 border-collapse border border-gray-300 ">
          <thead>
            <tr>
              <th className="px-4 py-3 border-b font-medium text-gray-600 bg-gray-100">Session Title</th>
              <th className="px-4 py-3 border-b font-medium text-gray-600 bg-gray-100">Description</th>
              <th className="px-4 py-3 border-b font-medium text-gray-600 bg-gray-100">Meeting Link</th>
              <th className="px-4 py-3 border-b font-medium text-gray-600 bg-gray-100">Actions</th>
            </tr>
          </thead>
          <tbody>
            {sessionsForInstructor.map(session => (
              <tr key={session.id} className="hover:bg-gray-50">
                <td className="px-4 py-2 border">{session.title}</td>
                <td className="px-4 py-2 border">{session.description}</td>
                <td className="px-4 py-2 border">
                  <a
                    href={session.meeting_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline hover:text-blue-700"
                  >
                    Link
                  </a>
                </td>
                <td className="px-4 py-2 border">
                  <button
                    className={`bg-red-500 text-white px-3 py-1 rounded focus:outline-none focus:ring-2 focus:ring-red-500 transition-opacity ${
                      loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-red-600'
                    }`}
                    onClick={() => handleUnassignSession(session.id)}
                    disabled={loading}
                  >
                    {loading ? 'Unassigning...' : 'Unassign'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <button
          onClick={onClose}
          className="bg-gray-500 text-white px-4 py-2 rounded-lg mt-4 focus:outline-none focus:ring-2 focus:ring-gray-400 hover:bg-gray-600 transition-opacity"
          ref={closeButtonRef}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default InstructorSessionsModal;
