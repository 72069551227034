import React, { useContext, useEffect, useState } from "react";
import CourseForm from "../../components/common/CourseForm";
import { Child, Session, useApi } from "../../context/ApiContext";
import { Course } from "../../context/ApiContext";
import ChildPreviewModal from "../base/home/components/ChildPreviewModal";

function ParentComponentCourses() {
  const { courses, createCourse, updateCourse, createSession, deleteCourse, child } =
    useApi();
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
  const [selectedChildren, setSelectedChildren] = useState<Child[]>(child);
  const [searchQuery, setSearchQuery] = useState(""); // State for search input
  const [filteredCourses, setFilteredCourses] = useState<Course[]>(courses); // Filtered courses list
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmationInput, setConfirmationInput] = useState("");
  const [actionLoading, setActionLoading] = useState(false);

  const handleAdd = () => {
    setIsAdding(true);
    setSelectedCourse(null);
  };

  const handlePreviewingChildren = (course: Course) => {
    setSelectedCourse(course);
    setIsPreviewing(!isPreviewing);
  };

  const handleEdit = (course: Course) => {
    setIsEditing(true);
    setSelectedCourse(course);
  };

  const handleDeleteRequest = (course: Course) => {
    setSelectedCourse(course);
    setIsDeleting(true);
  };

  const confirmDelete = async () => {
    if (confirmationInput === selectedCourse?.title) {
      setActionLoading(true);
      try {
        await deleteCourse(selectedCourse.id);
        setIsDeleting(false);
        setSelectedCourse(null);
      } catch (error) {
        console.error("Error deleting course:", error);
      } finally {
        setActionLoading(false);
      }
    } else {
      alert("Course name does not match!");
    }
  };

  const handleSubmit = async (courseData: Course, daysInWeek: number[]) => {
    if (isEditing) {
      await updateCourse(courseData.id, courseData);
      setIsEditing(false);
      setSelectedCourse(null);
    } else {
      const newCourse: any = await createCourse(courseData);
      const startDate = new Date(courseData.start_time);
      const endDate = new Date(courseData.end_time);
      const timeDifference = endDate.getTime() - startDate.getTime();
      const weeksToGenerate = Math.ceil(
        timeDifference / (1000 * 3600 * 24 * 7)
      );
      for (let week = 0; week < weeksToGenerate; week++) {
        for (const day of daysInWeek) {
          const sessionDate = new Date(startDate);
          sessionDate.setDate(
            startDate.getDate() +
              week * 7 +
              ((day - startDate.getDay() + 7) % 7)
          );
          if (sessionDate >= startDate && sessionDate <= endDate) {
            const sessionData: Session = {
              id: 0,
              title: `Session for ${sessionDate.toLocaleString()}`,
              description: "Default description",
              start_time: `${sessionDate.toISOString().split("T")[0]}T14:00:00`,
              end_time: `${sessionDate.toISOString().split("T")[0]}T15:00:00`,
              meeting_link: "",
              course: newCourse.id,
              attendance: [],
              is_Started: false,
              is_ended: false,
              absent: [],
              exact_start_date: sessionDate.toISOString().split("T")[0],
              exact_end_date: sessionDate.toISOString().split("T")[0],
            };
            await createSession(sessionData);
          }
        }
      }
      setIsAdding(false);
    }
  };

  useEffect(() => {
    const selectedChildInCourse: Child[] = [];
    child?.map((child) => {
      if (
        typeof selectedCourse?.id === "number" &&
        child.courses.includes(selectedCourse?.id)
      ) {
        selectedChildInCourse.push(child);
      }
    });
    setSelectedChildren(selectedChildInCourse);
  }, [selectedCourse]);

  useEffect(() => {
    // Filter courses based on search query
    const query = searchQuery.toLowerCase();
    const filtered = courses.filter((course) =>
      course.title.toLowerCase().includes(query)
    );
    setFilteredCourses(filtered);
  }, [searchQuery, courses]);

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-4">Manage Courses</h1>

      {/* Search bar */}
      <div className="mb-4">
        <input
          type="text"
          className="w-full p-2 border rounded-md"
          placeholder="Search courses..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {/* List of courses */}
      <div className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">Current Courses</h2>
        <table className="min-w-full bg-white shadow-md rounded-md">
          <thead>
            <tr>
              <th className="border-b p-2 text-left">Title</th>
              <th className="border-b p-2 text-left">Start Time</th>
              <th className="border-b p-2 text-left">End Time</th>
              <th className="border-b p-2 text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredCourses.map((course, idx) => (
              <tr key={idx}>
                <td className="border-b p-2">{course.title}</td>
                <td className="border-b p-2">{course.start_time}</td>
                <td className="border-b p-2">{course.end_time}</td>
                <td className="border-b p-2 text-right flex gap-2">
                  <button
                    className="bg-yellow-500 text-white p-2 rounded-md hover:bg-yellow-600 mr-2"
                    onClick={() => handleEdit(course)}
                  >
                    Edit
                  </button>
                  <button
                    className="bg-red-500 text-white p-2 rounded-md hover:bg-red-600"
                    onClick={() => handleDeleteRequest(course)}
                  >
                    Delete
                  </button>
                  <button
                    className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
                    onClick={() => handlePreviewingChildren(course)}
                  >
                    Preview Children   ({course.all_child})
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Add or Edit course form */}
      <div className="mb-6">
        {!isAdding && !isEditing && (
          <button
            className="bg-green-500 text-white p-2 rounded-md hover:bg-green-600"
            onClick={handleAdd}
          >
            Add New Course
          </button>
        )}

        {(isAdding || isEditing) && (
          <CourseForm course={selectedCourse} onSubmit={handleSubmit} />
        )}
      </div>
      <ChildPreviewModal
        isOpen={isPreviewing}
        onRequestClose={() => setIsPreviewing(false)}
        children={selectedChildren}
      />

      {/* Delete Confirmation Modal */}
      {isDeleting && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-6 rounded-md shadow-lg">
            <h3 className="text-xl font-semibold mb-4">Confirm Deletion</h3>
            <p className="mb-4">
              Enter the course name (<strong>{selectedCourse?.title}</strong>) to
              confirm deletion.
            </p>
            <input
              type="text"
              className="w-full border p-2 rounded-md mb-4"
              value={confirmationInput}
              onChange={(e) => setConfirmationInput(e.target.value)}
              placeholder="Course Name"
            />
            <div className="flex justify-end gap-4">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                onClick={() => setIsDeleting(false)}
              >
                Cancel
              </button>
              <button
                className={`px-4 py-2 rounded-md text-white ${
                  actionLoading
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-red-500 hover:bg-red-600"
                }`}
                onClick={confirmDelete}
                disabled={actionLoading}
              >
                {actionLoading ? "Deleting..." : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ParentComponentCourses;
