import React, { useState } from 'react';
import axios from 'axios';

interface InstructorFormProps {
  onClose: () => void;
  onInstructorAdded: () => void;
}

const InstructorForm: React.FC<InstructorFormProps> = ({ onClose, onInstructorAdded }) => {
  const [newInstructor, setNewInstructor] = useState({
    id: 0,
    user: {
      id: 0,
      username: '',
      phone_number: '',
      user_type: 'instructor',
      email: '',
      password: '',
    },
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [notification, setNotification] = useState<string | null>(null);
  const [passwordStrength, setPasswordStrength] = useState<string>('Weak');

  const validateField = async (field: string, value: string) => {
    let url = '';
    if (field === 'username') url = `https://codeoceantech.pythonanywhere.com///api/accounts/check-username/?username=${value}`;
    if (field === 'email') url = `https://codeoceantech.pythonanywhere.com///api/accounts/check-email/?email=${value}`;

    try {
      const response = await axios.get(url);
      if (response.data.exists) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `${field.charAt(0).toUpperCase() + field.slice(1)} already exists.`,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: '',
        }));
      }
    } catch (error) {
      console.error('Validation error:', error);
    }
  };

  const validatePhoneNumber = (phone: string) => {
    const phoneRegex = /^[0-9]{6,}$/; // Adjust regex based on your phone number format
    return phoneRegex.test(phone);
  };

  const checkPasswordStrength = (password: string) => {
    const lengthCriteria = password.length >= 8;
    const uppercaseCriteria = /[A-Z]/.test(password);
    const lowercaseCriteria = /[a-z]/.test(password);
    const numberCriteria = /[0-9]/.test(password);
    const specialCharCriteria = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    const criteriaMet = [lengthCriteria, uppercaseCriteria, lowercaseCriteria, numberCriteria, specialCharCriteria].filter(Boolean).length;

    if (criteriaMet < 2) {
      setPasswordStrength('Weak');
    } else if (criteriaMet < 4) {
      setPasswordStrength('Medium');
    } else {
      setPasswordStrength('Strong');
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewInstructor((prev) => ({
      ...prev,
      user: { ...prev.user, [name]: value },
    }));

    // Validate username and email fields
    if (name === 'username' || name === 'email') {
      await validateField(name, value);
    }

    // Validate phone number if phone_number field is updated
    if (name === 'phone_number') {
      if (!validatePhoneNumber(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone_number: 'Phone number must be 10 digits.',
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone_number: '',
        }));
      }
    }

    // Check password strength if password field is updated
    if (name === 'password') {
      checkPasswordStrength(value);
    }
  };

  const handleAddInstructor = async () => {
    setLoading(true);
    setNotification(null);
    try {
      await axios.post('https://codeoceantech.pythonanywhere.com///api/create/instructor/', newInstructor);
      setNotification('Instructor added successfully!');
      onInstructorAdded(); // Notify parent to refresh instructors
      setNewInstructor({
        id: 0,
        user: {
          id: 0,
          username: '',
          phone_number: '',
          user_type: 'instructor',
          email: '',
          password: '',
        },
      });
    } catch (error) {
      setNotification('Error adding instructor. Please try again.');
      console.error('Error adding instructor:', error);
    }
    setLoading(false);
  };

  return (
    <div>
      <h2 className="text-xl mb-4 font-semibold">Add New Instructor</h2>
      {notification && <p className="mb-4 text-green-600">{notification}</p>}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Username</label>
        <input
          type="text"
          name="username"
          value={newInstructor.user.username}
          onChange={handleChange}
          autoComplete="off"
          className={`mt-1 block w-full border-gray-300 rounded-md shadow-sm ${
            errors.username ? 'border-red-500' : 'border-gray-300'
          }`}
          placeholder="Enter username"
        />
        {errors.username && <p className="mt-1 text-sm text-red-500">{errors.username}</p>}
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Phone Number</label>
        <input
          type="text"
          name="phone_number"
          value={newInstructor.user.phone_number}
          onChange={handleChange}
          autoComplete="off"
          className={`mt-1 block w-full border-gray-300 rounded-md shadow-sm ${
            errors.phone_number ? 'border-red-500' : 'border-gray-300'
          }`}
          placeholder="Enter phone number"
        />
        {errors.phone_number && <p className="mt-1 text-sm text-red-500">{errors.phone_number}</p>}
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Email</label>
        <input
          type="email"
          name="email"
          value={newInstructor.user.email}
          onChange={handleChange}
          autoComplete="off"
          className={`mt-1 block w-full border-gray-300 rounded-md shadow-sm ${
            errors.email ? 'border-red-500' : 'border-gray-300'
          }`}
          placeholder="Enter email"
        />
        {errors.email && <p className="mt-1 text-sm text-red-500">{errors.email}</p>}
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Password</label>
        <input
          type="password"
          name="password"
          value={newInstructor.user.password}
          onChange={handleChange}
          autoComplete="off"
          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
          placeholder="Enter password"
        />
        <p className="mt-1 text-sm text-gray-600">Strength: {passwordStrength}</p>
      </div>
      <div className="flex gap-4">
        <button
          onClick={handleAddInstructor}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 transition-colors"
          disabled={loading}
        >
          {loading ? 'Adding...' : 'Add Instructor'}
        </button>
        <button
          onClick={onClose}
          className="bg-red-500 text-white px-4 py-2 rounded-lg shadow hover:bg-red-600 transition-colors"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default InstructorForm;
