import React, { useState, useRef, useEffect, useCallback } from "react";
import { MdClose } from "react-icons/md"; // Close icon

interface DropdownFilterProps {
  selectedValues: string[];
  options: string[];
  onChange: (selected: string[]) => void;
  placeholder?: string;
  multiple?: boolean;
}

const DropdownFilter: React.FC<DropdownFilterProps> = ({
  selectedValues,
  options,
  onChange,
  placeholder = "Select an option",
  multiple = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredOption, setHoveredOption] = useState<string | null>(null);
  const dropdownFilterRef = useRef<HTMLDivElement>(null);

  // Close dropdown when clicking outside
  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (dropdownFilterRef.current && !dropdownFilterRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  // Handle selection change (add/remove from selected values)
  const handleSelect = (option: string) => {
    let newSelectedValues: string[];

    if (multiple) {
      newSelectedValues = selectedValues.includes(option)
        ? selectedValues.filter((value) => value !== option) // Remove from selected
        : [...selectedValues, option]; // Add to selected
    } else {
      newSelectedValues = [option]; // Single selection, just set the option
      setIsOpen(false); // Close dropdown after selection if not multiple
    }
setIsOpen(!isOpen)
    onChange(newSelectedValues);
  };

  // Handle deselection of a selected value
  const handleDeselect = (option: string) => {
    const newSelectedValues = selectedValues.filter((value) => value !== option);
    onChange(newSelectedValues);
  };

  return (
    <div className="relative w-full" ref={dropdownFilterRef}>
      <div
        className="w-full p-2 border border-gray-300 rounded-md cursor-pointer bg-white shadow-sm flex flex-wrap items-center gap-2"
        onClick={() => setIsOpen((prev) => !prev)} // Toggle dropdown visibility
      >
        {selectedValues.length > 0 ? (
          selectedValues.map((value, index) => (
            <span key={index} className="flex items-center gap-1 bg-blue-500 text-white py-1 px-2 rounded-full text-sm">
              {value}
              <MdClose
                className="cursor-pointer text-xs"
                onClick={() => handleDeselect(value)}
              />
            </span>
          ))
        ) : (
          <span className="text-gray-500">{placeholder}</span>
        )}
      </div>

      {isOpen && (
        <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto transition-all duration-200 ease-in-out">
          {options.map((option, index) => (
            <li
              key={index}
              className={`p-2 cursor-pointer hover:bg-gray-100 transition-all duration-200 ease-in-out ${
                selectedValues.includes(option)
                  ? "bg-blue-400 text-white font-semibold hover:text-blue-400"
                  : "text-gray-800"
              }`}
              onClick={() => handleSelect(option)}
              onMouseEnter={() => setHoveredOption(option)} // Set hovered option
              onMouseLeave={() => setHoveredOption(null)} // Reset hovered option
              title={selectedValues.length && !multiple && !selectedValues.includes(option) ? 
                "Please deselect the current option first" : ""}
            >
              {option}
              {/* Show guide message if a single option is selected and another is hovered */}
              {selectedValues.length && !multiple && hoveredOption && (
                hoveredOption !== option && (
                  <div className="text-xs text-red-500 mt-1">
                    Deselect the current option before selecting a new one.
                  </div>
                )
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownFilter;
