import React, { useState, useEffect, useContext } from "react";
import { Problem, useApi } from "../../context/ApiContext";
import { AuthContext } from "../../context/AuthContext";
import Modal from "../../components/Feeds/Modal";

const ProblemControl: React.FC = () => {
  const {
    problems,
    fetchProblems,
    deleteProblem,
    updateProblem,
    sessions,
    courses,
  } = useApi();

  // State management
  const [sessionNumber, setSessionNumber] = useState<number | "">("");
  const [contentFilter, setContentFilter] = useState<string>("");
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedProblem, setSelectedProblem] = useState<Problem | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [actionLoading, setActionLoading] = useState<number | null>(null);
  const [actionFeedbackMessage, setActionFeedbackMessage] = useState<
    string | null
  >(null);

  const authContext = useContext(AuthContext);

  // Redirect to login if not authenticated
  useEffect(() => {
    if (!authContext) {
      window.location.href = "/login";
    }
  }, [authContext]);

  // Fetch problems
  useEffect(() => {
    const fetchData = async () => {
      if (!authContext?.token?.access) return;
      setLoading(true);
      try {
        await fetchProblems();
      } catch (error) {
        console.error("Error fetching problems:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [authContext?.token?.access]);

  // Filter problems
  const filteredProblems = problems.filter((problem) => {
    const matchesSessionNumber = sessionNumber
      ? problem.session === sessionNumber
      : true;
    const matchesContent = contentFilter
      ? problem.description.toLowerCase().includes(contentFilter.toLowerCase())
      : true;
    return matchesSessionNumber && matchesContent;
  });

  // Handle delete
  const handleDelete = async (id: number) => {
    if (window.confirm("Are you sure you want to delete this problem?")) {
      setActionLoading(id);
      try {
        await deleteProblem(id);
        setActionFeedbackMessage("Problem deleted successfully!");
        await fetchProblems();
      } catch (error) {
        console.error("Error deleting problem:", error);
        setActionFeedbackMessage("Failed to delete problem.");
      } finally {
        setActionLoading(null);
        setTimeout(() => setActionFeedbackMessage(null), 3000);
      }
    }
  };

  // Handle modal save
  const handleSave = async (description: string, sessionId: number) => {
    if (selectedProblem) {
      setLoading(true);
      try {
        await updateProblem(selectedProblem.id, {
          description,
          session: sessionId,
        });
        setActionFeedbackMessage("Problem updated successfully!");
      } catch (error) {
        console.error("Error updating problem:", error);
        setActionFeedbackMessage("Failed to update problem.");
      } finally {
        setModalOpen(false);
        setSelectedProblem(null);
        setLoading(false);
        setTimeout(() => setActionFeedbackMessage(null), 3000);
      }
    }
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {/* Header */}
      <header className="mb-8 bg-white shadow-md rounded-lg px-6 py-4">
        <h1 className="text-3xl font-semibold text-gray-800">
          Problem Control
        </h1>
        <p className="text-gray-600">
          Manage, filter, and edit problems effectively.
        </p>
      </header>

      {/* Filters Section */}
      <section className="bg-white shadow-md rounded-lg mb-8 p-6">
        <h2 className="text-xl font-medium text-gray-800 mb-4">Filters</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label
              htmlFor="sessionNumber"
              className="block text-sm font-medium text-gray-600"
            >
              Session Number
            </label>
            <input
              id="sessionNumber"
              type="number"
              value={sessionNumber}
              onChange={(e) => setSessionNumber(Number(e.target.value) || "")}
              className="w-full mt-1 px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter session number"
            />
          </div>
          <div>
            <label
              htmlFor="contentFilter"
              className="block text-sm font-medium text-gray-600"
            >
              Content Filter
            </label>
            <input
              id="contentFilter"
              type="text"
              value={contentFilter}
              onChange={(e) => setContentFilter(e.target.value)}
              className="w-full mt-1 px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter problem description"
            />
          </div>
        </div>
        <div className="flex justify-end mt-6">
          <button
            onClick={() => {
              setSessionNumber("");
              setContentFilter("");
            }}
            className="mr-2 px-4 py-2 bg-red-500 text-white text-sm font-medium rounded-md hover:bg-red-600 focus:ring-2 focus:ring-red-300"
          >
            Reset Filters
          </button>
          <button
            onClick={fetchProblems}
            className={`px-4 py-2 text-white text-sm font-medium rounded-md ${
              loading
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-300"
            }`}
            disabled={loading}
          >
            {loading ? "Refreshing..." : "Refresh"}
          </button>
        </div>
      </section>

      {/* Problem List */}
      <section className="bg-white  rounded-lg p-6">
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Problems
          </h2>
          {loading ? (
            <div className="flex justify-center items-center">
              <p>Loading problems...</p>
              {/* Optionally replace this text with a spinner */}
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredProblems.map((problem) => {
                const session = sessions?.find(
                  (session) => session.id === problem.session
                );
                const course = courses?.find(
                  (course) => course.id === session?.course
                );

                return (
                  <div
                  key={problem.id}
                  className="bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 flex flex-col justify-between"
                >
                  {/* Problem Information */}
                  <div className="flex flex-col gap-3">
                    <p className="font-semibold text-xl text-gray-800">
                      {session?.title} - {course?.title || "Unknown Course"}
                    </p>
                    <p className="text-gray-600 text-base">{problem.description}</p>
                    <p className="text-sm text-gray-400 italic">Owner: {problem.owner}</p>
                  </div>
                
                  {/* Action Buttons */}
                  <div className="mt-6 flex items-center justify-between">
                    <button
                      onClick={() => {
                        setSelectedProblem(problem);
                        setModalOpen(true);
                      }}
                      className="flex-1 px-5 py-2 mr-2 bg-green-600 text-white font-medium text-sm rounded-lg shadow-md hover:bg-green-700 hover:scale-105 transition-transform duration-300"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(problem.id)}
                      className={`flex-1 px-5 py-2 ml-2 text-white font-medium text-sm rounded-lg shadow-md transition-transform duration-300 ${
                        actionLoading === problem.id
                          ? "bg-gray-400 cursor-not-allowed"
                          : "bg-red-600 hover:bg-red-700 hover:scale-105"
                      }`}
                      disabled={actionLoading === problem.id}
                    >
                      {actionLoading === problem.id ? "Deleting..." : "Delete"}
                    </button>
                  </div>
                </div>
                
                );
              })}
            </div>
          )}
        </div>
      </section>

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSave={handleSave}
        feedbackContent={selectedProblem?.description || ""}
        sessionId={selectedProblem?.session || 0}
      />
    </div>
  );
};

export default ProblemControl;
