import React, { useState } from 'react';
import axios from 'axios';

interface Child {
  name: string;
  age: number;
  experience: string;
}

interface ParentCreationFormProps {
  parent?: {
    username: string;
    password: string;
    email: string;
    phone_number: string;
    children: Child[];
  } | null;
  onSubmit: (formData: {
    username: string;
    password: string;
    email: string;
    phone_number: string;
    children: Child[];
  }) => void;
}

const ParentCreationForm: React.FC<ParentCreationFormProps> = ({ parent, onSubmit }) => {
  const [formData, setFormData] = useState({
    username: parent?.username || '',
    password: parent?.password || '',
    email: parent?.email || '',
    phone_number: parent?.phone_number || '',
    children: parent?.children || [{ name: '', age: 0, experience: '' }],
  });

  const [errors, setErrors] = useState({
    username: '',
    email: '',
    phone_number: '',
  });

  const [success, setSuccess] = useState({
    username: '',
    email: '',
    phone_number: '',
  });

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index?: number) => {
    const { name, value } = e.target;

    if (index !== undefined) {
      // Handle changes to child data
      const updatedChildren = formData.children.map((child, i) =>
        i === index ? { ...child, [name]: value } : child
      );
      setFormData({ ...formData, children: updatedChildren });
    } else {
      setFormData({ ...formData, [name]: value });

      // Perform async validation when user updates username, email, or phone_number
      if (name === 'username' || name === 'email' || name === 'phone_number') {
        await validateField(name, value);
      }
    }
  };

  const validateField = async (field: string, value: string) => {
    try {
      let url = '';
      if (field === 'username') url = `https://codeoceantech.pythonanywhere.com///api/accounts/check-username/?username=${value}`;
      if (field === 'email') url = `https://codeoceantech.pythonanywhere.com///api/accounts/check-email/?email=${value}`;
      if (field === 'phone_number') url = `https://codeoceantech.pythonanywhere.com///api/accounts/check-phone-number/?phone_number=${value}`;

      const response = await axios.get(url);
      if (response.data.exists) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `${field.charAt(0).toUpperCase() + field.slice(1)} already exists. Try something else.`,
        }));
        setSuccess((prevSuccess) => ({ ...prevSuccess, [field]: '' }));
      } else {
        setSuccess((prevSuccess) => ({
          ...prevSuccess,
          [field]: `${field.charAt(0).toUpperCase() + field.slice(1)} is available.`,
        }));
        setErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
      }
    } catch (error) {
      console.error('Error validating field:', error);
    }
  };

  const handleAddChild = () => {
    setFormData({
      ...formData,
      children: [...formData.children, { name: '', age: 0, experience: '' }],
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Check for any validation errors before submitting
    if (errors.username || errors.email || errors.phone_number) {
      alert('Please fix validation errors before submitting');
      return;
    }

    onSubmit(formData);
  };

  return (
    <div className="p-6 bg-white shadow-md rounded-md max-w-md mx-auto">
      <h2 className="text-2xl font-semibold mb-4">{parent ? 'Edit Parent' : 'Add New Parent'}</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Username Field */}
        <div>
          <label className="block text-sm font-medium">Username</label>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            className={`mt-1 block w-full p-2 border rounded-md ${errors.username ? 'border-red-500' : success.username ? 'border-green-500' : ''}`}
            required
          />
          {errors.username && <p className="text-red-500 text-sm">{errors.username}</p>}
          {success.username && <p className="text-green-500 text-sm">{success.username}</p>}
        </div>
        {/* Password Field */}
        <div>
          <label className="block text-sm font-medium">Password</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border rounded-md"
            required
          />
        </div>
        {/* Email Field */}
        <div>
          <label className="block text-sm font-medium">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={`mt-1 block w-full p-2 border rounded-md ${errors.email ? 'border-red-500' : success.email ? 'border-green-500' : ''}`}
            required
          />
          {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
          {success.email && <p className="text-green-500 text-sm">{success.email}</p>}
        </div>
        {/* Phone Number Field */}
        <div>
          <label className="block text-sm font-medium">Phone Number</label>
          <input
            type="text"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            className={`mt-1 block w-full p-2 border rounded-md ${errors.phone_number ? 'border-red-500' : success.phone_number ? 'border-green-500' : ''}`}
            required
          />
          {errors.phone_number && <p className="text-red-500 text-sm">{errors.phone_number}</p>}
          {success.phone_number && <p className="text-green-500 text-sm">{success.phone_number}</p>}
        </div>
        {/* Children Section */}
        <div>
          <h3 className="text-lg font-semibold">Children</h3>
          {formData.children.map((child, index) => (
            <div key={index} className="mb-4">
              <div>
                <label className="block text-sm font-medium">Child Name</label>
                <input
                  type="text"
                  name="name"
                  value={child.name}
                  onChange={(e) => handleChange(e, index)}
                  className="mt-1 block w-full p-2 border rounded-md"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium">Age</label>
                <input
                  type="number"
                  name="age"
                  value={child.age}
                  onChange={(e) => handleChange(e, index)}
                  className="mt-1 block w-full p-2 border rounded-md"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium">Experience</label>
                <textarea
                  name="experience"
                  value={child.experience}
                  onChange={(e) => handleChange(e, index)}
                  className="mt-1 block w-full p-2 border rounded-md"
                  rows={3}
                />
              </div>
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddChild}
            className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
          >
            Add Child
          </button>
        </div>
        {/* Submit Button */}
        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
        >
          {parent ? 'Update Parent' : 'Add Parent'}
        </button>
      </form>
    </div>
  );
};

export default ParentCreationForm;
