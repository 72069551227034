import React, { useEffect, useState } from "react";
import { Child, useApi } from "../../context/ApiContext";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai"; // Import the eye icon

interface Subscription {
  id: number;
  start_date: string;
  end_date: string;
  subscription_type: "paid" | "gift";
  is_active: boolean;
}

function Children() {
  const { child } = useApi();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredChildren, setFilteredChildren] = useState<Child[]>([]);
  const [selectedChild, setSelectedChild] = useState<Child | null>(null);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [isPreviewing, setIsPreviewing] = useState(false);

  useEffect(() => {
    setFilteredChildren(
      child.filter((c) =>
        c.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, child]);

  const handlePreviewingChild = async (child: Child) => {
    setSelectedChild(child);
    try {
      const response = await axios.get(
        `https://codeoceantech.pythonanywhere.com///api/subscriptions/?childName=${child.name}`
      );
      setSubscriptions(response.data || []);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
      setSubscriptions([]);
    }
    setIsPreviewing(true); // Show the modal
  };

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-4">Manage Children</h1>

      <div className="mb-6">
        <input
          type="text"
          placeholder="Search for a child..."
          className="w-full p-2 border border-gray-300 rounded-md mb-4"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {filteredChildren.map((child, idx) => (
            <div
              key={idx}
              className="p-4 bg-gray-100 rounded-md shadow-md flex justify-between items-center"
            >
              <div>
                <p className="mb-2">
                  <strong>Name:</strong> {child.name}
                </p>
                <p className="mb-2">
                  <strong>Age:</strong> {child.age}
                </p>
                <p className="mb-2">
                  <strong>Experience:</strong> {child.experience}
                </p>
                <p className="mb-2">
                  <strong>Username:</strong> {child.clear_username}
                </p>
              </div>
              <button
                className="text-blue-500 hover:text-blue-600"
                onClick={() => handlePreviewingChild(child)}
                aria-label={`Preview subscriptions for ${child.name}`}
              >
                <AiOutlineEye size={24} />
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Preview Modal */}
      {isPreviewing && selectedChild && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md shadow-lg max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">
              Subscriptions for {selectedChild.name}
            </h2>
            {subscriptions.length > 0 ? (
              subscriptions.map((sub) => (
                <div
                  key={sub.id}
                  className="border rounded-md p-4 mb-4 bg-gray-50"
                >
                  <p>
                    <strong>Start Date:</strong> {sub.start_date}
                  </p>
                  <p>
                    <strong>End Date:</strong> {sub.end_date}
                  </p>
                  <p>
                    <strong>Type:</strong> {sub.subscription_type}
                  </p>
                  <p>
                    <strong>Status:</strong>{" "}
                    {sub.is_active ? "Active" : "Inactive"}
                  </p>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No subscriptions found.</p>
            )}
            <button
              className="mt-4 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              onClick={() => setIsPreviewing(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Children;
